<template>
  <Popup @close="$emit('close')" cols="col-xl-5 col-xxl-4">
    <div class="row mx-0 justify-content-center">
      <div class="col-12 text-center mb-3 green-text bold font19">
        Share the reward...
      </div>
      <div class="col-12 text-center mb-3">
        Why not share the benefits, reward and protection with your loved ones?
      </div>
      <div class="col-12 text-center letter-spacing small medium mb-1">
        ADD FAMILY MEMBERS:
      </div>
      <div class="col-8 col-md-4 text-center mb-3">
        <NumberField color="goldgold" v-model="familyMembers" id="familyMembers" />
      </div>
      <div class="col-12 text-center mb-3">
        <div class="form-check form-switch">
          <label class="form-check-label small" for="flexSwitchCheckDefault">Donate the same amount as my DailySadaqah</label>
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="sameAmount">
        </div>
      </div>
      <div class="col-12" v-if="!sameAmount">
        <div class="row mx-0 justify-content-center">
          <div class="col-12 text-center letter-spacing small medium mb-2">
            DAILY AMOUNT PER MEMBER:
          </div>
          <div class="col-10 col-md-10 col-lg-8 col-xl-7 p-0 text-center mb-4">
            <div class="row justify-content-center">
              <div class="col-auto text-center mb-2 px-2" v-for="amount in familyAmounts" :key="amount">
                <div class="select_amount select_amount_size" :class="{selected: amount === selectedFamilyAmount}" @click="useSelectedFamily(amount)">
                  {{defaultCurrency}}{{amount}}
                </div>
              </div>
              <div class="col mb-2 px-2">
                <CurrencyField type="text" placeholder="0.00" id="amount" v-model="customFamilyAmount" :currencySymbol="defaultCurrency" :color="{goldgold: customFamilyAmount, gold: !customFamilyAmount }" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 text-center letter-spacing small medium mb-1">
        DAILY TOTAL
      </div>
      <div class="col-12 text-center bold font19 mb-3">
        {{displayCurrency(donationTotal)}}
      </div>
      <div class="col-12 text-center mb-2" >
        <Button color="green" btnText="Continue Setup" icon="arrow" @buttonClicked="continueWith">
          <IconArrowForward size="size20" />
        </Button>
      </div>
      <div class="col-12 text-center" >
        <Button color="transparent" btnText="Continue without additional members" size="xsmall" @buttonClicked="continueWithout" />
      </div>
    </div>
  </Popup>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  props: ['baseAmount', 'modelValue'],
  emits: ['update:modelValue', 'continue', 'close'],
  computed: {
    totalAmountValue: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    },
    donationTotal () {
      if (this.baseAmount && this.baseAmount > 0) {
        if (this.sameAmount) {
          if (this.familyMembers && this.familyMembers > 0) {
            return this.baseAmount * (this.familyMembers + 1)
          } else {
            return this.baseAmount
          }
        } else {
          if (this.familyMembers && this.familyMembers > 0 && this.donationFamilyAmount && this.donationFamilyAmount > 0) {
            return parseFloat(this.baseAmount) + (parseFloat(this.donationFamilyAmount) * (parseFloat(this.familyMembers)))
          } else {
            return this.baseAmount
          }
        }
      } else {
        return 0
      }
    }
  },
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    CurrencyField: defineAsyncComponent(() => import('@/components/CurrencyField.vue')),
    NumberField: defineAsyncComponent(() => import('@/components/NumberField.vue')),
    Popup: defineAsyncComponent(() => import('@/views/Popup.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue'))
  },
  name: 'Share Reward',
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      sameAmount: true,
      selectedFamilyAmount: null,
      customFamilyAmount: null,
      familyAmounts: ['1', '2', '3'],
      donationFamilyAmount: null,
      familyMembers: 0
    }
  },
  watch: {
    customFamilyAmount () {
      this.checkFamilyAmount()
    }
  },
  methods: {
    displayCurrency (val) {
      return this.defaultCurrency + ' ' + parseFloat(Math.round(val * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    continueWith () {
      this.totalAmountValue = this.donationTotal
      this.$emit('continue')
    },
    continueWithout () {
      this.familyMembers = 0
      this.totalAmountValue = this.donationTotal
      this.$emit('continue')
    },
    useSelectedFamily (val) {
      this.customFamilyAmount = null
      this.selectedFamilyAmount = val
      this.donationFamilyAmount = this.selectedFamilyAmount
    },
    checkFamilyAmount () {
      if (this.customFamilyAmount || this.customFamilyAmount === 0 || this.customFamilyAmount === '') {
        this.selectedFamilyAmount = null
        this.donationFamilyAmount = this.customFamilyAmount
      }
    }
  }
}
</script>
<style scoped>
.letter-spacing {
  letter-spacing: 4px;
}
.select_amount {
  color: var(--gold-color);
  font-weight: 700;
  background-color: var( --green-color-dark );
  cursor: pointer;
}
.select_amount:hover {
  background-color: var( --green-color );
}
.select_amount_size {
  border-radius: 50%;
  height: 44px;
  width: 44px;
  line-height: 44px;
}
.select_amount.selected {
  color: var( --green-color-dark );
  background-color: var(--gold-color);
}
.form-switch {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch .form-check-input {
  margin-left: 1em;
  position: absolute;
}
</style>
